import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl, FormControlDirective, FormControlName, UntypedFormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl, ReactiveFormsModule,
} from '@angular/forms';
import {debounceTime, distinctUntilChanged, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable, Observer, of, Subject, Subscription} from 'rxjs';
import {TypeaheadMatch, TypeaheadModule} from 'ngx-bootstrap/typeahead';

// Сервисы
import { FormService } from '../../services/form.service';
import { ValidationService } from '../../services/validation.service';
import { DaData } from '../../enums/da-data.enum';
import {DadataService} from "../../services/dadata.service";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {FormFieldErrorComponent} from "../form-field-error/form-field-error.component";
import {getModelCategoryNameByModelId} from "../../functions/getCarBrandAndModel";

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteComponent),
    multi: true,
  }],
  imports: [
    NgIf,
    FormFieldErrorComponent,
    ReactiveFormsModule,
    NgClass,
    TypeaheadModule,
    JsonPipe
  ]
})
export class AutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, DoCheck {
  // Событие выбора элемента
  @Output() typeaheadOnSelect = new EventEmitter<any>();
  // Заголовок
  @Input() label!: string;
  // Placeholder
  @Input() placeholder!: string;
  // Массив данных
  @Input() typeaheadList!: any[];
  // Исходный массив данных
  @Input() originalTypeaheadList!: any[];
  // Массив асинхронных данных
  @Input() asyncTypeaheadList!: Observable<any>;
  // Получаем данные асинхронно
  @Input() typeaheadAsync!: boolean;
  // Значение для value
  @Input() typeaheadOptionField = 'value';
  // Значение для id
  @Input() idKeyName = 'id';
  // Показывать scroll в списке
  @Input() typeaheadScrollable = true;
  // Количество видимых элементов в списке
  @Input() typeaheadOptionsInScrollableView = 7;
  // Минимальное количество элементов для показа в списке
  @Input() typeaheadMinLength = 0;
  // Лимит элементов в списке
  @Input() typeaheadOptionsLimit = 7;
  // Только для чтения
  @Input() isReadonly!: boolean;
  // Используем кастомный template для dropdown
  @Input() isCustomTemplate!: boolean;
  // Используем кастомный template для dropdown
  @Input() isFilter!: boolean;
  // Имя контролла которое валидируем
  @Input() validationControlName!: string;
  // Системное имя для сервиса DaData
  @Input() suggestionPart!: string;
  // Имя контролла, который содержить данные DaData
  @Input() daDataControlName!: string;
  // Только выбор, без автокомплита
  @Input() isDropdown!: boolean;
  // Кастомизированный dropdown
  @Input() isCustomDropdown!: boolean;

  protected readonly getModelCategoryNameByModelId = getModelCategoryNameByModelId;

  // Контрол
  public control!: UntypedFormControl;
  // Контрол поиска
  public searchControl: UntypedFormControl = new UntypedFormControl();
  // Контрол поиска
  public daDataControl!: UntypedFormControl;
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Подписка на контрол поиска
  private searchSubscription!: Subscription;
  // Подписка на контрол поиска
  private subjectDaData: Subject<any> = new Subject<any>();
  // Сообщения валидации
  public messages: any = {};
  // Флаг валидности контрола
  private valid!: boolean;
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Имя контрола
  public injectedControlName = '';

  // Вызовем когда значение изменится
  private onTouched: any = () => {};

  // Вызовем при любом действии пользователя с контроллом
  private onChange: any = () => {};

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly dadataService: DadataService,
              private readonly validationService: ValidationService) {

  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    if (this.daDataControlName) {
      this.daDataControl = (this.control.parent as UntypedFormGroup)?.get(this.daDataControlName) as UntypedFormControl;
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }

    // Если есть системное имя для сервиса DaDada, то берем данные оттуда
    if (this.suggestionPart) {
      this.controlIsSuggestion();
    }

    if (this.isCustomDropdown) {
      this.originalTypeaheadList = [...this.typeaheadList];

      this.searchControl.valueChanges
        .subscribe((value: string) => {
          if (value) {
            this.typeaheadList = this.originalTypeaheadList?.filter((item) => {
              return item.modelName?.toLowerCase()?.includes(value?.toString()?.toLowerCase());
            });
          } else {
            this.typeaheadList = [...this.originalTypeaheadList];
          }
        });
    }
  }

  // Инициализация завершена
  public ngAfterViewInit(): void {
  }

  public ngDoCheck(): void {
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  get getHeightDropdownList(): string {
    return this.typeaheadList?.length >= 7 ? 7 * 50 + 'px' : this.typeaheadList?.length < 7 ? this.typeaheadList?.length * 50 + 'px' : '240px';
  }

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    this.injectedControlName = injectedControlName;
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }
    if (propertyControl?.placeholder) {
      this.placeholder = propertyControl.placeholder;
    }
    if (propertyControl?.['typeaheadOptionField']) {
      this.typeaheadOptionField = propertyControl['typeaheadOptionField'];
    }
    if (propertyControl?.['idKeyName']) {
      this.idKeyName = propertyControl['idKeyName'];
    }
    if (propertyControl?.['typeaheadMinLength']) {
      this.typeaheadMinLength = propertyControl['typeaheadMinLength'];
    }

    this.validationService.setControlValidation(propertyControl, this.control);
  }

  // Если данные автокомплита берем из сервиса Dadata
  public controlIsSuggestion(): void {
    this.subscription.closed = false;
    // Флаг асинхронного запроса
    this.typeaheadAsync = true;

    // Слушаем изменение контрола и делаем запрос в сервис DaData
    // Таймаут запроса 500 миллисекунд
    this.asyncTypeaheadList = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.searchControl.value);
    }).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.isLoading = true),
      switchMap((query: string) => {
        if (!query || query === this.control.value) {
          this.isLoading = false;
          return  of([]);
        } else {
          this.getSuggestionData(query);
          return query ? this.subjectDaData : of([]);
        }
      }),
      finalize(() => this.isLoading = false)
    );
  }

  // Получаем данные из сервиса DaData
  public getSuggestionData(query: string): void {
    // Подписываем на получение данных из сервиса DaData
    // Если запрос отменили, то subscription снова открываем после отмены
    this.subscription.add(this.dadataService.suggestionAddress(query, this.suggestionPart)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((data: any) => this.subjectDaData.next(data)));
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(selected: any): void {
    if (!this.typeaheadAsync && this.typeaheadList && this.typeaheadList.length && selected !== null && this.idKeyName) {
      this.typeaheadList
        .filter((item) => item[this.idKeyName] && item[this.idKeyName]?.toString() === selected?.toString())
        .map((item) => this.searchControl.setValue(item[this.typeaheadOptionField], { emitEvent: false }));
    } else {
      this.searchControl.setValue(selected);
      this.onChange(selected);
      this.onTouched();
    }
  }

  // Сохраняем обратный вызов для изменений
  public registerOnChange(fn: any): void {
    // this.onChange = fn;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
  }

  // Событие выбора элемента
  public selectItem(event: any): void {
    if (this.daDataControlName && this.daDataControl) {
      const value = event.item.value;
      this.daDataControl.setValue(Object.assign({ value }, event.item.data));
    }
    this.control.setValue(event instanceof TypeaheadMatch ? event.item[this.idKeyName] : event[this.idKeyName]);
    this.searchControl.setValue(
      event instanceof TypeaheadMatch ? event.item[this.typeaheadOptionField] : event[this.typeaheadOptionField]
    );
    this.onChange(event);
    this.onTouched();
    this.typeaheadOnSelect.emit(event);
  }

  // Событие фокуса на контролле поиска
  public focusEvent(): void {
    if (this.suggestionPart) {
      this.controlIsSuggestion();
    }

    if (this.isCustomDropdown) {
      const value = this.searchControl.value;
      if (value) {
        this.typeaheadList = this.originalTypeaheadList.filter((item) => {
          return item.modelName?.toLowerCase()?.includes(value?.toString()?.toLowerCase());
        });
      } else {
        this.typeaheadList = [...this.originalTypeaheadList];
      }
    }
  }

  // Если убрали фокус с контролла
  public blurEvent(event: any): void {
    setTimeout(() => {
      this.control.markAsTouched();
      this.subscription.unsubscribe();

      if (!event.target.value) {
        this.control.reset();
        this.searchControl.reset();
        this.control.markAsTouched();
      } else {
        if (!this.typeaheadAsync) {
          // Ищем значение в массиве, если есть, то применяем его, если нет, то сбрасываем
          const findValue = this.typeaheadList.find((item) =>
            item[this.typeaheadOptionField]?.toLowerCase()?.trim() === event.target.value.toLowerCase().trim());
          if (findValue) {
            // this.selectItem(new TypeaheadMatch(findValue));
          } else if (this.searchControl.value && !this.isCustomDropdown) {
            const findValueSearchControl = this.typeaheadList.find((item) =>
              item[this.typeaheadOptionField]?.toLowerCase()?.trim() === this.searchControl.value?.toLowerCase().trim());
            if (findValueSearchControl) {
              this.selectItem(new TypeaheadMatch(findValueSearchControl));
            } else if (this.control.value) {
              const findValueParentControl = this.typeaheadList.find((item) =>
                item[this.idKeyName]?.toString()?.toLowerCase()?.trim() === this.control.value.toString()?.toLowerCase()?.trim());
              if (findValueParentControl) {
                // this.searchControl.setValue(findValueParentControl[this.typeaheadOptionField]);
                // this.control.setValue(findValueParentControl[this.idKeyName]);
                this.selectItem(new TypeaheadMatch(findValueParentControl));
              }
            } else {
              this.searchControl.reset();
              this.control.reset();
            }
          } else {
            this.searchControl.reset();
            this.control.reset();
          }
        } else {
          if (this.suggestionPart === DaData.ADDRESS) {
            this.searchControl.setValue(this.control.value);
          } else {
            this.control.setValue(this.searchControl.value ? this.searchControl.value.trim() : this.searchControl.value);
          }
        }

        // Если получаем данные с сервиса DaData,
        // То после потери фокуса, нужно применить значение, что осталось
        // Это в случае если мы не выбрали элемент из списка, но изменили его
        if (this.suggestionPart !== DaData.ADDRESS) {
          // this.control.setValue(this.searchControl.value ? this.searchControl.value.trim() : this.searchControl.value);
        }
        this.control.markAsTouched();
      }

    }, 100);

  }

}
