import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { FormService } from "./form.service";

@Injectable({
  providedIn: 'root'
})
export class GuardService {
  private router = inject(Router);
  private formService = inject(FormService);

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (state.url.includes('/success')) {
      return true;
    }
    if (state.url.includes('/prolongation')) {
      return true;
    }
    if (state.url.includes('/data-checking')) {
      return true;
    }

    const applicationId = this.formService.form.get('applicationId')?.value;

    if (!applicationId) {
      // Сохраняем параметры текущего URL
      const queryParams = next.queryParams;

      // Редирект на страницу авторизации с сохранением параметров
      return this.router.createUrlTree(['/'], { queryParams });
    }

    return true;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  return inject(GuardService).canActivate(next, state);
}

