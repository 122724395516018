@if (kbmResults.length >= 1 && kbmResults[0].kbm! <= 1) {
  <div class="card h-auto shadow-0 border-0 p-0 info-filter-block custom-rounded mb-2 mb-sm-2">
    <div class="card-body p-0">
      <div class="row p-3 p-sm-4 align-items-center" (click)="isShowEditInfo = !isShowEditInfo"
           [attr.aria-expanded]="!isShowEditInfo" aria-controls="collapseBasic">
        <div class="col-auto">
          <div class="kbm-graph">
            <div class="value">
              {{ kbmResults[0].kbm! }}
            </div>
            <div>КБМ</div>
          </div>
        </div>
        <div class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start text-wrap">
          @if (kbmResults[0].kbm! < 1) {
            <div class="main-title-item mb-1">Скидка {{ (((kbmResults[0].kbm! - 1) * 100) * -1).toFixed(0) }}%</div>
          } @else if (kbmResults[0].kbm! === 1) {
            <div class="main-title-item mb-1"> Нет скидки за безаварийность</div>
          }
        </div>
        <div class="col-auto">
          <div class="d-flex justify-content-between">
            <a class="btn-edit">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20 10C18.0222 10 16.0888 10.5865 14.4443 11.6853C12.7998 12.7841 11.5181 14.3459 10.7612 16.1732C10.0043 18.0004 9.8063 20.0111 10.1922 21.9509C10.578 23.8907 11.5304 25.6725 12.9289 27.0711C14.3275 28.4696 16.1093 29.422 18.0491 29.8078C19.9889 30.1937 21.9996 29.9957 23.8268 29.2388C25.6541 28.4819 27.2159 27.2002 28.3147 25.5557C29.4135 23.9112 30 21.9778 30 20C29.9971 17.3487 28.9426 14.8068 27.0679 12.9321C25.1932 11.0574 22.6513 10.0029 20 10ZM20 28.3333C18.3518 28.3333 16.7407 27.8446 15.3703 26.9289C13.9998 26.0132 12.9317 24.7117 12.301 23.189C11.6703 21.6663 11.5053 19.9908 11.8268 18.3742C12.1483 16.7577 12.942 15.2729 14.1074 14.1074C15.2729 12.942 16.7577 12.1483 18.3743 11.8268C19.9908 11.5052 21.6663 11.6703 23.189 12.301C24.7118 12.9317 26.0132 13.9998 26.9289 15.3702C27.8446 16.7407 28.3333 18.3518 28.3333 20C28.3309 22.2094 27.4522 24.3276 25.8899 25.8899C24.3276 27.4522 22.2094 28.3309 20 28.3333ZM20 16.6669C20.6904 16.6669 21.25 16.1072 21.25 15.4169C21.25 14.7265 20.6904 14.1669 20 14.1669C19.3096 14.1669 18.75 14.7265 18.75 15.4169C18.75 16.1072 19.3096 16.6669 20 16.6669ZM20.8317 19.1665C20.8317 18.7062 20.4586 18.3331 19.9984 18.3331C19.5381 18.3331 19.165 18.7062 19.165 19.1665V24.9998C19.165 25.46 19.5381 25.8331 19.9984 25.8331C20.4586 25.8331 20.8317 25.46 20.8317 24.9998L20.8317 19.1665Z"
                      fill="#98A2B3"/>
              </svg>
            </a>
            <!--                        <button type="button" class="btn btn-outline-primary btn-sm btn-edit btn-edit-icon" (click)="openEditInfoModal()">-->
            <!--                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                                <path d="M20.2929 7.20711C20.6834 6.81658 20.6834 6.18342 20.2929 5.7929L18.2071 3.70711C17.8166 3.31658 17.1834 3.31658 16.7929 3.70711L15 5.5L18.5 9L20.2929 7.20711Z" fill="#C5C5C7"/>-->
            <!--                                <path d="M7.5 20L18 9.5L14.5 6L4 16.5V20H7.5Z" fill="#C5C5C7"/>-->
            <!--                            </svg>-->
            <!--                        </button>-->
          </div>
        </div>
      </div>

      <div id="collapseBasic" class="px-3 px-sm-4" [collapse]="isShowEditInfo" [isAnimated]="true">

        <div class="alert alert-secondary alert-dismissible border-0 mb-3" role="alert">
          <div>Для расчета ОСАГО всегда используется самый высокий КБМ (безаварийность) из всех добавленных водителей
          </div>
        </div>

        <div class="drivers-kbm">
          @for (driver of kbmResults; track driver) {
            <div class="driver-kbm p-3 p-sm-4 mb-3 mb-sm-4">
              <div class="driver-kbm-name">
                <strong>{{ driver.driverLastName }} {{ driver.driverFirstName }}</strong>
                @if (driver.usedForCalculation) {
                  КБМ применен к расчету
                }
              </div>
              <div class="driver-kbm-values">
                @if (driver.kbm! < 1) {
                  <strong
                    [class]="driver.kbm! <= 1 ? 'text-success' : 'text-warning'">Скидка {{ (((driver.kbm! * 100) - 100) * -1).toFixed(0) }}%
                  </strong>
                  КБМ {{ driver.kbm! }}
                } @else if (driver.kbm! === 1) {
                  Нет скидки за безаварийность
                }
              </div>
            </div>
          }
        </div>

      </div>


    </div>
  </div>
}
