import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormService} from "../../../shared/services/form.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AppService} from "../../../shared/services/app.service";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import {ICarBrand} from "../../../shared/interface/car-brands.interface";
import {concatMap, finalize} from "rxjs/operators";
import {Subscription} from "rxjs";
import {CarDocumentTypesEnum} from "../../../shared/enums/car-document-types.enum";
import {CarIdentificatorsEnum} from "../../../shared/enums/car-identificators.enum";
import {ContentService} from "../../../shared/services/content.service";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {CacheService} from "../../../shared/services/cache.service";
import {WidgetStatuses} from "../../../shared/enums/widgetStatuses.enum";
import {OsagoService} from "../../../shared/services/osago.service";
import {YandexMetrikaService} from "../../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../../shared/enums/yandex-metrika-goals.enum";
import {FormSwitchComponent} from "../../../shared/components/form-switch/form-switch.component";
import {FormLicenseFieldComponent} from "../../../shared/components/form-license-field/form-license-field.component";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {PrePriceCalculatorComponent} from "./pre-price-calculator/pre-price-calculator.component";
import {getAlertModelId} from "../../../shared/functions/getCarBrandAndModel";
import {ROUTES} from "../../../shared/enums/routes.enum";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-car-data',
  standalone: true,
  templateUrl: './car-data.component.html',
  imports: [
    ReactiveFormsModule,
    FormSwitchComponent,
    FormLicenseFieldComponent,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormDateFieldComponent,
    PrePriceCalculatorComponent,
    JsonPipe
  ],
  styleUrls: ['./car-data.component.scss']
})
export class CarDataComponent implements OnInit, OnDestroy {
  @Output() submitCarDataForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              public readonly contentService: ContentService,
              public readonly cacheService: CacheService,
              private readonly osagoService: OsagoService,
              private readonly ym: YandexMetrikaService) {
  }

  // Индикатор загруки
  public isLoading = false;
  // Форма
  public form: FormGroup = this.formService.form.get('carData') as FormGroup;
  // Подписка
  public subscription = new Subscription();
  // Типы авто документа
  public carDocumentTypes = CarDocumentTypesEnum;
  // Идентификаторы авто
  public carIdentificators = CarIdentificatorsEnum;
  // Форма
  public licenseFormGroup = this.formService.licenseFormGroup;

  // Получаем сообщение о выбраном классе модели авто
  protected readonly getAlertModelId = getAlertModelId;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CarDataPage);
    const hasNoLicensePlate = this.licenseFormGroup?.get('hasNoLicensePlate')?.value;

    if (hasNoLicensePlate) {
      setTimeout(() => {
        this.formService.licenseFormGroup.get('licensePlate')?.reset();
        this.formService.licenseFormGroup.get('licensePlate')?.clearValidators();
        this.formService.licenseFormGroup.get('licensePlate')?.updateValueAndValidity();
      }, 100)
    } else {
      setTimeout(() => {
        this.formService.licenseFormGroup.get('licensePlate')?.setValidators([Validators.pattern('[А-Яа-яЁё]{1}[0-9]{3}[А-Яа-яЁё]{2}[0-9]{2,3}')]);
        this.formService.licenseFormGroup.get('licensePlate')?.updateValueAndValidity();
        this.formService.licenseFormGroup.get('licensePlate')?.markAllAsTouched();
      }, 100)
    }

    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.subscribeForm();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Подписка на форму
  private subscribeForm(): void {
    const licensePlateControl =  this.formService.licenseFormGroup.get('licensePlate');
    const hasNoLicensePlateControl =  this.formService.licenseFormGroup.get('hasNoLicensePlate');

    this.subscription.add(
      licensePlateControl?.valueChanges?.subscribe((value) => {
        hasNoLicensePlateControl?.setValue(!value);
      })
    );
  }

  // Нажали "продолжить"
  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      scrollToFirstError();
      return;
    }

    this.submitCarDataForm.emit(true);

    this.subscription.add(
      this.cacheService.saveCache()
        .pipe(
          concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.CarScreen))
        )
        .subscribe()
    );
  }

  // Выбрали бренд авто из списка
  public selectBrand(selectItem: TypeaheadMatch): void {
    this.form.get('modelId')?.disable();
    // Выбранные элемент бренда авто
    const carBrand: ICarBrand = selectItem?.item;
    // Применяем его ID к контроллу brandId
    // this.form.get('brandId')?.setValue(carBrand.brandId);
    // Сбрасываем предыдущее значение модели
    this.form.get('modelId')?.reset();
    // this.form.get('modelName')?.reset();
    // Выбираем HTMLElement элемент с ID = modelId

    // Получаем список моделей по выбранному бренду ID
    this.subscription.add(
      this.appService.getCarModels(carBrand.brandId)
        .pipe(finalize(() => this.form.get('modelId')?.enable()))
        .subscribe()
    );

  }

  public selectModel(): void {
    const modelId = this.form.get('modelId')?.value;
    if (!modelId) {
      this.form.get('horsePower')?.reset();
      this.form.get('horsePower')?.disable();
      this.form.get('productionYear')?.reset();
      this.form.get('productionYear')?.disable();
      return;
    }

    this.subscription.add(
      this.appService.getProductionYears(modelId)
        .pipe(finalize(() => this.form.get('productionYear')?.enable()))
        .subscribe({
          next: (years: number[]) => {
            this.form.get('productionYear')?.setValue(null);
          },
          error: () => {
            this.form.get('productionYear')?.reset();
          }
        })
    );

  }

  public selectProductionYear(): void {
    const modelId = this.form.get('modelId')?.value;
    const productionYear = this.form.get('productionYear')?.value;

    if (!modelId || !productionYear) {
      this.form.get('horsePower')?.reset();
      this.form.get('horsePower')?.disable();
      return;
    }

    // this.form.get('horsePower')?.disable();

    this.subscription.add(
      this.appService.getModifications(modelId, productionYear)
        .pipe(finalize(() => this.form.get('horsePower')?.enable()))
        .subscribe({
          next: (modifications: number[]) => {
            this.form.get('horsePower')?.setValue(null);
          },
          error: () => {
            this.form.get('horsePower')?.reset();
          }
        })
    );
  }


  // Выбрали новый номер авто
  public onChangeNewLicense(licenseControlIsValid: boolean): void {
    const licensePlateControl = this.formService.licenseFormGroup.get('licensePlate');

    // Номер авто снова обязателен для заполнения
    licensePlateControl?.setValidators([Validators.pattern('[А-Яа-яЁё]{1}[0-9]{3}[А-Яа-яЁё]{2}[0-9]{2,3}')]);
    licensePlateControl?.updateValueAndValidity();

    if (licenseControlIsValid) {

      if (licensePlateControl?.invalid) {
        licensePlateControl.markAsTouched()
        return;
      }

      this.isLoading = true;

      this.osagoService.setWidgetStatus(WidgetStatuses.CalculateWithoutLicense)
        .pipe(
          concatMap(() => this.osagoService.startLicenseVerification()),
          finalize(() => {
            scrollToFirstError();
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (res) => {

          },
          error: (e) => {
            this.isLoading = false;
          }
        });
    }
  }
}
