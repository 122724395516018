import {inject, Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {HttpService} from "./http.service";
import {FormService} from "./form.service";
import {AppService} from './app.service';
import {concatMap, delay, EMPTY, expand, last, Observable, of, retry, retryWhen, takeWhile, timer} from "rxjs";
import {IUrlParams} from "../interface/url-params.interface";
import {environment} from "@environment/environment";
import {SettingsService} from "./settings.service";
import {catchError, map, switchMap, take, tap} from "rxjs/operators";
import {IOffer, IUpsales} from "../interface/offers.interface";
import {v4 as uuidv4} from "uuid";
import {WidgetStatuses} from "../enums/widgetStatuses.enum";
import {getApplicationSendRequest} from "../functions/applicationSendRequest";
import {resetCarData} from "../functions/resetForm";
import {ApiResponse} from "../interface/api-response";
import {IBaseApplicationRequest} from "../interface/application.interface";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {YandexMetrikaService} from "./yandex-metrika.service";
import {LoggingService} from "./loggingService";

export interface IApiKeySettings {
  returnToOffersUrlRequestEnabled: boolean;
  // ... other fields ...
}

@Injectable({
  providedIn: 'root'
})
export class OsagoService extends HttpService {
  private readonly ym = inject(YandexMetrikaService);
  private readonly loggingService = inject(LoggingService);
  protected override http = inject(HttpClient);

  constructor(private readonly formService: FormService,
              private readonly settingsService: SettingsService,
              private readonly appService: AppService) {
    super()
  }

  // Список офферов
  public offers: IOffer[] = [];
  // Показываем данные формы для редактирования
  public isShowEditInfo = false;
  // Показываем форму даты начала действия полиса
  public isShowFormStartDatePolicy = false;
  // Показываем подсказку о продлении полиса в случае если нашли полис клиента
  public isShowProlongationAlert = false;
  // Оффер пролонгации
  prolongationOffer: any | null = null;
  // Ссылка на оплату
  public paymentLink!: string;
  // Выбранный оффер
  public selectedOffer!: IOffer | null;
  // Статус анкеты из запроса GetAppInfo
  public appStatus = '';
  // Статус получения ссылки на оплату
  public isLoadingFromCreatePolicy = false;
  // Список статусов для кеширования запросов
  private widgetStatusCache: Set<WidgetStatuses> = new Set();

  public returnUrl: string | null = null;
  public apiKeySettings: IApiKeySettings | null = null;

  public getBaseApplicationRequest(): IBaseApplicationRequest {
    const {value} = this.formService.form;
    return {
      apiKey: this.settingsService.apiKey,
      applicationId: value.applicationId
    };
  }

  // Получаем данные авто по номеру авто
  public startLicenseVerification(): Observable<any> {
    const urlParams = this.appService.getUrlParams();
    const license = this.formService.licenseFormGroup?.get('licensePlate')?.value;

    if (license == null || license.length === 0) {
      return of();
    }

    return this.createCarCharacteristicsReport(urlParams, license)
      .pipe(
        concatMap((res) => res.result && res.value?.requestId
          ? this.getLicenseVerificationResultWithRetry(res.value.requestId)
          : of()
        ),
        concatMap((res) => {
          if (res?.result && res?.value?.carData) {
            resetCarData(this.formService.licenseFormGroup);
            return this.appService.getCarModels(res?.value?.carData?.brandId).pipe(
              tap((carModels) => {
                setTimeout(() => {
                  this.formService.setCarDataFromCharacteristicRequest(res.value?.carData);
                });
              })
            );
          } else {
            this.formService.form.get('carCharacteristicsRequestId')?.reset();
            resetCarData(this.formService.licenseFormGroup);
            return of(); // Возвращаем пустой Observable в случае ошибки или отсутствия данных
          }
        })
      );
  }

// Создаем ключ для получения данных авто
  private createCarCharacteristicsReport(urlParams: IUrlParams, license: string): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: urlParams.applicationId,
      clientId: this.settingsService.clientId,
      licensePlate: license
    };
    return this.http.post(environment.hostUrl + 'enrichment/CreateCarCharacteristicsReport', request);
  }

// Получаем данные авто по ключу с повторными запросами в случае 'Processing'
  private getLicenseVerificationResultWithRetry(requestId: string): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      requestId
    };

    return this.getLicenseVerificationResult(request).pipe(
      concatMap((res) => {
        if (res?.value?.status === 'Processing') {
          // Если статус 'Processing', повторяем запрос с задержкой в 1 секунду
          return timer(1000).pipe(concatMap(() => this.getLicenseVerificationResultWithRetry(requestId)));
        } else {
          this.formService.form.get('carCharacteristicsRequestId')?.setValue(requestId);
          return of(res);
        }
      })
    );
  }

// Получаем данные авто по ключу
  private getLicenseVerificationResult(request: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'enrichment/GetCarCharacteristicsReport', request)
      .pipe(
        tap((res: any) => {
          if (res && !res.result) {
            // this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetCarCharacteristicsReportResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса enrichment/GetCarCharacteristicsReport', error);
          this.loggingService.error('Ошиба запроса enrichment/GetCarCharacteristicsReport');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetCarCharacteristicsReport);
          return of(null);
        })
      );
    ;
  }

  // Получить информацию о текущей анкете
  public getAppInfo(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId
    };
    return this.post(environment.apiUrl + 'app/GetApplicationInfo', request)
      .pipe(
        tap((response: any) => {
          if (response.result) {
            this.appStatus = response.result && response?.value?.applicationData.applicationStatus;
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/GetApplicationInfo', error);
          this.loggingService.error('Ошиба запроса app/GetApplicationInfo');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetApplicationInfo);
          return of(null);
        })
      );
  }

  // Отправляем данные в страховые компании - sendToInsuence и получаем офферов
  public sendToInsurersGetOffers(insurerType?: string): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      EnabledOnlyInsurers: insurerType ? [insurerType] : undefined
    };
    return this.post(environment.apiUrl + 'app/SendToInsurers', request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            // this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SendToInsurersResultError);
          }
        }),
        expand(() => this.getOffers()),
        last(),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/SendToInsurers', error);
          this.loggingService.error('Ошиба запроса app/SendToInsurers');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSendToInsurers);
          return of(null);
        })
      );
  }

  // Запрос на получение офферов
  public getOffers(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
    };

    return this.post(environment.apiUrl + 'app/GetOffers', request)
      .pipe(
        delay(2000),
        takeWhile(
          (res) => {
            if (res && res.result) {
              // Проверяем, есть ли вообще офферы в ответе
              const offers = res.value.offers || [];

              // Создаем новый Set из существующих офферов
              const offersSet = new Set(this.offers.map((offer: IOffer) => offer.offerId));

              // Фильтруем только уникальные офферы и сохраняем их порядок
              const uniqueOffers = offers.filter((offer: IOffer) => !offersSet.has(offer.offerId));

              // Добавляем уникальные офферы к текущему списку
              this.offers = [...this.offers, ...uniqueOffers];

              // Сортируем this.offers по порядку из res.value.offers
              this.offers.sort((a, b) => {
                const aIndex = offers.findIndex((offer: IOffer) => offer.offerId === a.offerId);
                const bIndex = offers.findIndex((offer: IOffer) => offer.offerId === b.offerId);
                return aIndex - bIndex;
              });

              // Возвращаем false, если все офферы получены, иначе true
            } else if (res && !res.result) {
              this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
              this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetOffersResultError);
            }

            return !res.value.allOffersReceived;
          }
        ),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/GetOffers', error);
          this.loggingService.error('Ошиба запроса app/GetOffers');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOffers);
          return of(null);
        })
      );
  }

  public sendOsagoApplication(): Observable<any> {
    this.appService.setWidgetDisplayedStatus = false;
    const request = getApplicationSendRequest(this.formService.form.value);
    request.apiKey = this.settingsService.apiKey;
    return this.post(environment.apiUrl + 'app/SendOsagoApplication', request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SendOsagoApplicationResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/SendOsagoApplication', error);
          this.loggingService.error('Ошиба запроса app/SendOsagoApplication');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSendOsagoApplication);
          return of(null);
        })
      );
  }

  // Выбрали оффера
  public selectOffer(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      offerId: this.selectedOffer?.offerId,
    };
    return this.post(environment.apiUrl + 'app/SelectOffer', request)
      .pipe(
        map((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SelectOfferResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/SelectOffer', error);
          this.loggingService.error('Ошиба запроса app/SelectOffer');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSelectOffer);
          return of(null);
        })
      );
  }

  // Получаем ссылку на оплату
  public getPaymentLinkRepeatedly(offerId?: string, paymentMethod?: string): Observable<any> {
    const { value } = this.formService.form;
    const selectedUpsales = this.selectedOffer?.upsales?.filter((upsale: IUpsales) => upsale.isSelected).map((upsale: IUpsales) => upsale.id)
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      offerId: offerId ? offerId : this.selectedOffer?.offerId,
      skipNotification: this.appService.isArmApplication,
      selectedUpsales,
      sbp: paymentMethod === 'paymentSBPMethod'
    };

    return this.post(environment.apiUrl + 'app/GetOsagoPaymentLink', request)
      .pipe(
        expand((res) => {
          // Если статус не "Received", повторяем запрос через 2 секунды
          if (res?.value?.status !== 'Received' && res?.result) {
            return timer(2000).pipe(
              switchMap(() => this.post(environment.apiUrl + 'app/GetOsagoPaymentLink', request))
            );
          } else {
            // Если получили "Received" или результат false, завершаем цепочку
            return EMPTY;
          }
        }),
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetOsagoPaymentLinkResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошибка запроса app/GetOsagoPaymentLink', error);
          this.loggingService.error('Ошибка запроса app/GetOsagoPaymentLink');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOsagoPaymentLink);
          return of(null);
        })
      );
  };

  public updateOffersList(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId
    };

    return this.post(environment.apiUrl + 'app/GetOffers', request)
      .pipe(
        delay(2000),
        takeWhile((res: any) => {
          // Создаем новый Set из существующих офферов
          const offersSet = new Set(this.offers.map((offer: IOffer) => offer.offerId));

          // Фильтруем только уникальные офферы
          const uniqueOffers = res.value.offers.filter((offer: IOffer) => !offersSet.has(offer.offerId));

          // Добавляем уникальные офферы к текущему списку
          this.offers = [...this.offers, ...uniqueOffers];

          return !res?.value?.allOffersReceived;
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/GetOffers в функции updateOffersList', error);
          this.loggingService.error('Ошиба запроса app/GetOffers в функции updateOffersList');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOffers);
          return of(null);
        })
      );
  }

  public setWidgetStatus(widgetStatus: WidgetStatuses): Observable<any> {
    if (this.widgetStatusCache.has(widgetStatus)) {
      console.info(`Запрос на статус ${widgetStatus} уже был сделан в текущей сессии. Повторный запрос не отправляется.`);
      return of(null); // Возвращаем пустое Observable, чтобы предотвратить повторный запрос
    }

    this.widgetStatusCache.add(widgetStatus);

    const { value } = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      applicationStatus: widgetStatus
    };

    return this.post(environment.apiUrl + 'app/SetWidgetStatus', request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            // this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SetWidgetStatusResultError);
          }
        }),
        take(1),
        catchError(error => {
          this.loggingService.trace('Ошибка запроса app/SetWidgetStatus', error);
          this.loggingService.error('Ошибка запроса app/SetWidgetStatus');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSetWidgetStatus);
          return of(null);
        })
      );
  }

  sendOsagoPaymentLink(): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      sendSms: true
    };
    return this.http.post<any>(environment.apiUrl + `app/SendOsagoPaymentLink`, request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SendOsagoPaymentLinkResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса app/SendOsagoPaymentLink', error);
          this.loggingService.error('Ошиба запроса app/SendOsagoPaymentLink');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOsagoPaymentLink);
          return of(null);
        })
      );
  }

  public setStatusBuyButtonPressed(): Observable<ApiResponse<any> | null> {
    let retryAttempts = 0;

    return this.post(environment.apiUrl + 'app/SetStatusBuyButtonPressed', this.getBaseApplicationRequest())
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SetStatusBuyButtonPressedResultError);
          }
        }),
        catchError((error) => {
          this.loggingService.trace('Ошибка запроса app/SetStatusBuyButtonPressed', error);
          this.loggingService.error('Ошибка запроса app/SetStatusBuyButtonPressed');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSetStatusBuyButtonPressedError);
          throw error; // Перебрасываем ошибку для обработки повторов
        }),
        retry({
          count: 5, // Максимум 5 попыток
          delay: (retryCount) => {
            retryAttempts++;
            this.loggingService.error(`Повторный запрос через 1 секунду. Попытка ${retryAttempts}`);
            return timer(1000); // Интервал между попытками — 1 секунда
          },
          resetOnSuccess: true, // Сбрасывать счетчик на успешных запросах
        }),
        catchError(() => {
          this.loggingService.error('Превышено количество попыток. Остановка запросов.');
          return of(null); // Возвращаем null после превышения количества попыток
        })
      );
  }



  // Получаем ссылку на оплату
  public saveCaptcha(captcha: string): Observable<any> {
    return this.http.post(environment.hostUrl + 'captcha/recaptcha', {
        clientId: this.settingsService.clientId,
        sessionId: this.settingsService.sessionId,
        captcha: captcha
      })
      .pipe(take(1));
  }

  // Меняем канал возврата
  public setReturnChannel(): Observable<any> {
    const {value} = this.formService.form;
    return this.http.post(environment.apiUrl + 'app/SetReturnChannel', {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      returnClientChannelType: 'DataCheckingBeforePaymentSms',
    })
      .pipe(take(1));
  }

  // Add new methods
  public getReturnToOfferUrl(): Observable<any> {
    const { value } = this.formService.form;
    const request = {
      applicationId: value?.applicationId,
      apiKey: this.settingsService.apiKey
    };

    return this.post(environment.apiUrl + 'partner/GetReturnToOfferUrlRequest', request)
      .pipe(
        tap((res) => {
          if (res?.result && res?.value?.returnUrl) {
            this.returnUrl = res.value.returnUrl;
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошибка запроса partner/GetReturnToOfferUrlRequest', error);
          this.loggingService.error('Ошибка запроса partner/GetReturnToOfferUrlRequest');
          return of(null);
        })
      );
  }

  public getApiKeySettings(): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey
    };

    return this.post(environment.apiUrl + 'partner/GetApiKeySettings', request)
      .pipe(
        tap((res) => {
          if (res) {
            this.apiKeySettings = res.value;
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошибка запроса partner/GetApiKeySettings', error);
          this.loggingService.error('Ошибка запроса partner/GetApiKeySettings');
          return of(null);
        })
      );
  }
}
