@if (offer.upsales && offer.upsales.length) {
  <div class="py-2 px-3 mt-4 cross" [ngStyle]="{'background': isDisabled ? '#EFF8FF' : '#F2F4F7'}">

    @if (offer.useUpsales) {
      @for (upsale of osagoService.selectedOffer?.upsales!; track upsale) {
        @if (upsale.isSelected && upsale?.isApproved && upsale.priceChanged) {
          <div class="alert alert-warning mb-0 mt-3" role="alert">
            Обратите внимание: цена за <strong>"{{upsale.name}}"</strong> изменилась. Теперь она составляет <strong>{{upsale.price}} ₽</strong>
          </div>
        }
      }
    }

      @if (isDisabled) {
        <div class="cross-description my-2">
          @for (upsale of osagoService.selectedOffer?.upsales!; track upsale) {
            @if (upsale.isSelected && upsale?.isApproved === null || upsale?.isApproved) {
              @if (!osagoService.paymentLink) {
                <div class="spinner-border text-primary spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              }
              Включено в стоимость
            }
          }
        </div>
      }

    <div class="row">
      @for (upsaleItem of offer.upsales; track upsaleItem) {
        @if (isDisabled && upsaleItem.isSelected) {
          <app-upsale-item [offer]="offer" [isDisabled]="isDisabled" [upsaleItem]="upsaleItem"
                           (checkedChange)="onCheckedChange(upsaleItem.id, $event)"></app-upsale-item>
        } @else if (!isDisabled) {
          <app-upsale-item [offer]="offer" [isDisabled]="isDisabled" [upsaleItem]="upsaleItem"
                           (checkedChange)="onCheckedChange(upsaleItem.id, $event)"></app-upsale-item>
        }
      }
    </div>
  </div>
}

<!--<pre>{{ osagoService?.selectedOffer?.upsales! | json }}</pre>-->
