@if (upsaleItem.isOptional && !isDisabled) {
  <div class="col">
    <div class="row justify-content-between align-items-center">
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" [id]="upsaleItem.id" [checked]="upsaleItem.isSelected" (change)="onCheckboxChange($event)">
          <label class="form-check-label" [for]="upsaleItem.id">
            {{ upsaleItem.name }}
            <div class="text-secondary">
              + {{ upsaleItem.price }} ₽
            </div>
          </label>
        </div>
      </div>
      <div class="col-auto">

        <div class="row align-items-center">
          <div class="col-auto">
            @if (isCollapsed) {
              <svg class="collapse-icon" (click)="isCollapsed = !isCollapsed"
                   [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.6232 16.3266C28.3819 16.1175 28.0546 16 27.7134 16C27.3722 16 27.0449 16.1175 26.8036 16.3266L20.902 21.4423C20.6574 21.6459 20.3314 21.7595 19.9922 21.7595C19.6531 21.7595 19.3271 21.6459 19.0824 21.4423L13.1809 16.3266C12.9382 16.1234 12.6131 16.011 12.2757 16.0135C11.9383 16.0161 11.6155 16.1334 11.3769 16.3402C11.1383 16.547 11.003 16.8268 11 17.1193C10.9971 17.4118 11.1268 17.6935 11.3612 17.9039L17.2615 23.0196C17.62 23.3304 18.0456 23.577 18.5141 23.7452C18.9825 23.9134 19.4846 24 19.9916 24C20.4986 24 21.0007 23.9134 21.4691 23.7452C21.9376 23.577 22.3632 23.3304 22.7217 23.0196L28.6232 17.9039C28.8645 17.6947 29 17.4111 29 17.1153C29 16.8195 28.8645 16.5358 28.6232 16.3266Z" fill="#98A2B3"/>
              </svg>
            } @else {
              <svg class="collapse-icon" (click)="isCollapsed = !isCollapsed"
                   [attr.aria-expanded]="isCollapsed" aria-controls="collapseBasic" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3768 23.6734C11.6181 23.8825 11.9454 24 12.2866 24C12.6278 24 12.9551 23.8825 13.1964 23.6734L19.098 18.5577C19.3426 18.3541 19.6686 18.2405 20.0078 18.2405C20.3469 18.2405 20.6729 18.3541 20.9176 18.5577L26.8191 23.6734C27.0618 23.8766 27.3869 23.989 27.7243 23.9865C28.0617 23.9839 28.3845 23.8666 28.6231 23.6598C28.8617 23.453 28.997 23.1732 29 22.8807C29.0029 22.5882 28.8732 22.3065 28.6388 22.0961L22.7385 16.9804C22.38 16.6696 21.9544 16.423 21.4859 16.2548C21.0175 16.0866 20.5154 16 20.0084 16C19.5014 16 18.9993 16.0866 18.5309 16.2548C18.0624 16.423 17.6368 16.6696 17.2783 16.9804L11.3768 22.0961C11.1355 22.3053 11 22.5889 11 22.8847C11 23.1805 11.1355 23.4642 11.3768 23.6734Z" fill="#98A2B3"/>
              </svg>
            }
          </div>
        </div>

      </div>
    </div>

    <div class="mt-3" id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
      @for (description of upsaleItem.descriptions; track description) {
        <div class="upsale-name">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0021 5.1794L7.94174 13.6028C7.88755 13.6596 7.82315 13.7048 7.75223 13.7355C7.68131 13.7663 7.60528 13.7822 7.52848 13.7822C7.45169 13.7822 7.37566 13.7663 7.30474 13.7355C7.23382 13.7048 7.16942 13.6596 7.11523 13.6028L3.99817 10.3421C3.94397 10.2852 3.87957 10.2401 3.80865 10.2093C3.73774 10.1785 3.6617 10.1627 3.58491 10.1627C3.50812 10.1627 3.43208 10.1785 3.36117 10.2093C3.29025 10.2401 3.22585 10.2852 3.17165 10.3421C3.11725 10.3987 3.07408 10.466 3.04462 10.5401C3.01516 10.6142 3 10.6937 3 10.774C3 10.8542 3.01516 10.9337 3.04462 11.0078C3.07408 11.0819 3.11725 11.1492 3.17165 11.2059L6.28988 14.4641C6.61882 14.8073 7.0646 15 7.52936 15C7.99412 15 8.43989 14.8073 8.76884 14.4641L16.8286 6.04258C16.883 5.98596 16.9261 5.91869 16.9555 5.84463C16.9849 5.77057 17 5.69117 17 5.61099C17 5.53081 16.9849 5.45141 16.9555 5.37735C16.9261 5.30329 16.883 5.23602 16.8286 5.1794C16.7744 5.12254 16.71 5.07742 16.6391 5.04663C16.5682 5.01585 16.4922 5 16.4154 5C16.3386 5 16.2626 5.01585 16.1916 5.04663C16.1207 5.07742 16.0563 5.12254 16.0021 5.1794Z"/>
          </svg>
          {{description}}</div>
      }
    </div>
  </div>
} @else if (!upsaleItem.isOptional && !isDisabled) {
  <div class="row">
    @for (description of upsaleItem.descriptions; track description) {
      <div class="col-12 col-sm-auto mt-2">
        <div class="upsale-name">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0021 5.1794L7.94174 13.6028C7.88755 13.6596 7.82315 13.7048 7.75223 13.7355C7.68131 13.7663 7.60528 13.7822 7.52848 13.7822C7.45169 13.7822 7.37566 13.7663 7.30474 13.7355C7.23382 13.7048 7.16942 13.6596 7.11523 13.6028L3.99817 10.3421C3.94397 10.2852 3.87957 10.2401 3.80865 10.2093C3.73774 10.1785 3.6617 10.1627 3.58491 10.1627C3.50812 10.1627 3.43208 10.1785 3.36117 10.2093C3.29025 10.2401 3.22585 10.2852 3.17165 10.3421C3.11725 10.3987 3.07408 10.466 3.04462 10.5401C3.01516 10.6142 3 10.6937 3 10.774C3 10.8542 3.01516 10.9337 3.04462 11.0078C3.07408 11.0819 3.11725 11.1492 3.17165 11.2059L6.28988 14.4641C6.61882 14.8073 7.0646 15 7.52936 15C7.99412 15 8.43989 14.8073 8.76884 14.4641L16.8286 6.04258C16.883 5.98596 16.9261 5.91869 16.9555 5.84463C16.9849 5.77057 17 5.69117 17 5.61099C17 5.53081 16.9849 5.45141 16.9555 5.37735C16.9261 5.30329 16.883 5.23602 16.8286 5.1794C16.7744 5.12254 16.71 5.07742 16.6391 5.04663C16.5682 5.01585 16.4922 5 16.4154 5C16.3386 5 16.2626 5.01585 16.1916 5.04663C16.1207 5.07742 16.0563 5.12254 16.0021 5.1794Z"/>
          </svg>
          {{description}}</div>
      </div>
    }
  </div>
} @else if (isDisabled && (!upsaleItem.isOptional || upsaleItem.isApproved === null || (osagoService.paymentLink && upsaleItem.isApproved))) {

  <div class="row">
    @for (description of upsaleItem.descriptions; track description) {
      <div class="col-12 col-sm-auto mt-2">
        <div class="upsale-name">
          @if (!upsaleItem.isOptional || osagoService.paymentLink) {
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0021 5.1794L7.94174 13.6028C7.88755 13.6596 7.82315 13.7048 7.75223 13.7355C7.68131 13.7663 7.60528 13.7822 7.52848 13.7822C7.45169 13.7822 7.37566 13.7663 7.30474 13.7355C7.23382 13.7048 7.16942 13.6596 7.11523 13.6028L3.99817 10.3421C3.94397 10.2852 3.87957 10.2401 3.80865 10.2093C3.73774 10.1785 3.6617 10.1627 3.58491 10.1627C3.50812 10.1627 3.43208 10.1785 3.36117 10.2093C3.29025 10.2401 3.22585 10.2852 3.17165 10.3421C3.11725 10.3987 3.07408 10.466 3.04462 10.5401C3.01516 10.6142 3 10.6937 3 10.774C3 10.8542 3.01516 10.9337 3.04462 11.0078C3.07408 11.0819 3.11725 11.1492 3.17165 11.2059L6.28988 14.4641C6.61882 14.8073 7.0646 15 7.52936 15C7.99412 15 8.43989 14.8073 8.76884 14.4641L16.8286 6.04258C16.883 5.98596 16.9261 5.91869 16.9555 5.84463C16.9849 5.77057 17 5.69117 17 5.61099C17 5.53081 16.9849 5.45141 16.9555 5.37735C16.9261 5.30329 16.883 5.23602 16.8286 5.1794C16.7744 5.12254 16.71 5.07742 16.6391 5.04663C16.5682 5.01585 16.4922 5 16.4154 5C16.3386 5 16.2626 5.01585 16.1916 5.04663C16.1207 5.07742 16.0563 5.12254 16.0021 5.1794Z"/>
            </svg>
          } @else {
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0021 5.1794L7.94174 13.6028C7.88755 13.6596 7.82315 13.7048 7.75223 13.7355C7.68131 13.7663 7.60528 13.7822 7.52848 13.7822C7.45169 13.7822 7.37566 13.7663 7.30474 13.7355C7.23382 13.7048 7.16942 13.6596 7.11523 13.6028L3.99817 10.3421C3.94397 10.2852 3.87957 10.2401 3.80865 10.2093C3.73774 10.1785 3.6617 10.1627 3.58491 10.1627C3.50812 10.1627 3.43208 10.1785 3.36117 10.2093C3.29025 10.2401 3.22585 10.2852 3.17165 10.3421C3.11725 10.3987 3.07408 10.466 3.04462 10.5401C3.01516 10.6142 3 10.6937 3 10.774C3 10.8542 3.01516 10.9337 3.04462 11.0078C3.07408 11.0819 3.11725 11.1492 3.17165 11.2059L6.28988 14.4641C6.61882 14.8073 7.0646 15 7.52936 15C7.99412 15 8.43989 14.8073 8.76884 14.4641L16.8286 6.04258C16.883 5.98596 16.9261 5.91869 16.9555 5.84463C16.9849 5.77057 17 5.69117 17 5.61099C17 5.53081 16.9849 5.45141 16.9555 5.37735C16.9261 5.30329 16.883 5.23602 16.8286 5.1794C16.7744 5.12254 16.71 5.07742 16.6391 5.04663C16.5682 5.01585 16.4922 5 16.4154 5C16.3386 5 16.2626 5.01585 16.1916 5.04663C16.1207 5.07742 16.0563 5.12254 16.0021 5.1794Z"/>
            </svg>
            <!--              <div class="spinner-border text-primary spinner-border-sm" role="status">-->
              <!--                <span class="visually-hidden">Loading...</span>-->
              <!--              </div>-->
          }
          {{description}}</div>
      </div>
    }
  </div>

<!--  <pre>{{upsaleItem|json}}</pre>-->
}

