import {Component, inject, Input, OnInit} from '@angular/core';
import {JsonPipe, NgForOf, NgIf, NgStyle} from "@angular/common";
import {UpsaleItemComponent} from "./upsale-item/upsale-item.component";
import {IOffer, IUpsales} from 'src/app/shared/interface/offers.interface';
import {OsagoService} from "../../../../shared/services/osago.service";

@Component({
  selector: 'app-upsales',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    UpsaleItemComponent,
    JsonPipe,
    NgStyle
  ],
  templateUrl: './upsales.component.html',
  styleUrl: './upsales.component.scss'
})
export class UpsalesComponent implements OnInit{
  // Выбранный оффер
  @Input() offer!: IOffer;
  // Блокируем выбор кроса (для страницы перехода на оплату)
  @Input() isDisabled: boolean = false;
  // Сервис осаго
  public osagoService = inject(OsagoService);

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit() {
    // Нужно выделить крсос, который должен быть выделен по умолчанию
    this.offer.upsales?.map((item: IUpsales) => {
      // Если по умолчанию выбран крсос, то выбираем его и меняем цену
      if (item.isPreselected) {
        item.isSelected = item.isPreselected;
        this.offer.premiumAmount = this.offer?.premiumAmount + item.price;
      }
    });
  }
  // --------------------------------------------------------------------------

  // Событие выбора кросса
  public onCheckedChange(id: string, checked: boolean): void {
    this.offer?.upsales?.forEach((upsaleItem: any) => {
      if (upsaleItem.id === id) {
        upsaleItem.isSelected = checked;
      } else if (checked) {
        if (upsaleItem.isSelected) {
          this.offer.premiumAmount = this.offer?.premiumAmount - upsaleItem.price;
        }
        upsaleItem.isSelected = !checked;

      } else {
        if (upsaleItem.isSelected) {
          this.offer.premiumAmount = this.offer?.premiumAmount - upsaleItem.price;
        }
      }
    });
    this.offer.useUpsales = this.offer.upsales?.some(upsale => upsale.isSelected) || false;
  }


  // factorial func

}
