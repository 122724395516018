<h3 class="page-title my-3 my-sm-4">Выбор компании</h3>

<app-policy-data [isCollapse]="true"></app-policy-data>
<app-form-policy-date (changePolicyStartDate)="changePolicyStartDate($event)"></app-form-policy-date>
<app-kbm></app-kbm>

<div class="my-3">
  <a 
    *ngIf="canShare && osagoService.returnUrl" 
    [href]="osagoService.returnUrl"
    target="_blank"
    class="btn btn-primary share-button">
    Поделиться
  </a>
</div>

<app-timer [isLoading]="isLoading" [min]="3" [sec]="30"></app-timer>

<div class="offers-list">
  @for (offer of osagoService.offers; track offer; let i = $index) {
    <app-offer-item [offer]="offer"></app-offer-item>
  }
</div>

@if (isLoading) {
  <app-offer-placeholder></app-offer-placeholder>
}

@if (osagoService.offers.length === 0 && getOffersFinish) {
  <app-empty-offers-list></app-empty-offers-list>
}
